//
// Header Dark Skin
//




// Include global config
@import "../../../../../config";

// Include layout config
@import "../../../config";

// Include header config
@import "../../config";

$kt-header-itek-font-color: #ffffff;
$kt-header-icon-font-color: #6e7899;
$kt-header-item-hover-bg: #282f48;
$kt-header-bg-color: #536be2;

// Desktop mode
@include kt-desktop {
	.kt-header {
		background-color: kt-get($kt-layout-skins, dark);
		box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);

		// Menu
		.kt-header-menu {
			.kt-menu__nav {
				> .kt-menu__item {
					> .kt-menu__link {
						border-radius: 4px;

						.kt-menu__link-text {
							color: #c0c4d1;
							font-weight: 500;
						}

						.kt-menu__hor-arrow {
							color: #677191;
						}

						.kt-menu__hor-icon {
							color: #677191;
						}
					}

					&.kt-menu__item--here,
					&.kt-menu__item--active,
					&:hover {
						> .kt-menu__link {
							background-color: $kt-header-item-hover-bg;

							.kt-menu__link-text {
								color: $kt-header-itek-font-color;
							}

							.kt-menu__hor-arrow {
								color: $kt-header-itek-font-color;
							}

							.kt-menu__hor-icon {
								color: $kt-header-itek-font-color;
							}
						}
					}
				}
			}
		}
	}
}

// General mode
.kt-header {
	.kt-header__topbar {
		padding-right: 15px;

		.kt-aside-secondary--enabled & {
			padding-right: 0;
		}

		.kt-header__topbar-item {
			.kt-header__topbar-icon {
				i {
					color: $kt-header-icon-font-color;
				}

				svg {
					g {
						[fill] {
							fill: $kt-header-icon-font-color;
						}
					}
				}
			}

			// User profile
			&.kt-header__topbar-item--user {
				.kt-header__topbar-welcome {
					color: #6b7594;
				}

				.kt-header__topbar-username {
					color: #c0c4d1;
				}
			}

			// Quick panel
			&.kt-header__topbar--quick-panel-toggler {
				.kt-header__topbar-icon {
					i {
						color: $kt-header-icon-font-color;
					}
				}
			}

			&:hover,
			&.show {
				.kt-header__topbar-icon {
					background-color: $kt-header-item-hover-bg;

					i {
						color: #fff;
					}

					svg {
						g {
							[fill] {
								fill: #fff;
							}
						}
					}
				}

				&.kt-header__topbar-item--user {
					.kt-header__topbar-user {
						@include kt-transition();
						background-color: $kt-header-item-hover-bg;

						.kt-header__topbar-username {
							color: $kt-header-itek-font-color;
						}
					}

					.kt-header__topbar-user-2 {
						@include kt-transition();

						.notification-icon {
							background-color: #DAEBFB;

							img {
								filter: invert(47%) sepia(61%) saturate(2254%) hue-rotate(190deg) brightness(97%) contrast(90%);
							}
						}
					}
				}

				&.kt-header__topbar--quick-panel-toggler {
					background-color: kt-brand-color();

					.kt-header__topbar-icon {
						i {
							color: kt-brand-color(inverse);
						}
					}
				}
			}

			// Search
			&.kt-header__topbar-item--search {
				.kt-input-icon {
					.kt-input-icon__icon {
						i {
							color: kt-base-color(label, 2);
						}
					}
				}
			}
		}
	}
}

// Mobile mode
@include kt-tablet-and-mobile {
	// Menu wrapper
	.kt-header-menu-wrapper {
		background-color: kt-get($kt-layout-skins, dark);

		// Scrollbar
		@include kt-perfect-scrollbar-skin(#637099);
	}

	// Topbar
	.kt-header__topbar {
		background-color: kt-get($kt-layout-skins, dark);
		box-shadow: none;
	}

	.kt-header__topbar--mobile-on {
		.kt-header__topbar {
			box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
			border-top: 1px solid #2e3448;
		}
	}

	// Build Header Menu Skin
	@include kt-menu-hor-build-skin(kt-get($kt-header-config, menu, desktop), dark);
}
